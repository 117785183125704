<template>
  <div class="common-pane junction-info__pane">
    <div class="junction-info__pane-controls">
      <div
        class="controls-header"
        v-if="currentJunction.hasOwnProperty('groupId') || currentJunction.variables !== null"
        @click.self="isModalOpen = !isModalOpen"
      >
        <div class="controls-header__content">
          <div class="controls-header__content-title">
            {{ $t('message.junction.pane.groupType') }}
          </div>
          <div class="controls-header__content-value">
            {{ junctionItemName(currentJunction.id, currentJunction.groupId) }}
          </div>
        </div>
        <div class="chevron">
          <img src="@/assets/img/select.svg" alt="↓" />
        </div>
        <Group-modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
          <template #body>
            <Junctions-group
              :group="originalGroup"
              :currentSelected="currentJunction.id"
              @itemSelected="itemSelected"
            />
          </template>
        </Group-modal>
      </div>
      <div class="controls-footer">
        <div class="controls-footer__item">
          <div class="controls-footer__item-title">
            {{ $t('message.junction.pane.junctionType') }}
          </div>
          <div class="controls-footer__item-select" v-if="currentJunction.isCustom">
            <select
              name="type"
              v-model="currentJunction.type"
              @change="updateJunctionType"
              class="select"
            >
              <option value="chiseled">
                {{ $t('message.junction.pane.types.chiseled') }}
              </option>
              <option value="linear">
                {{ $t('message.junction.pane.types.linear') }}
              </option>
            </select>
          </div>
          <div class="controls-footer__item-select controls-footer__item-select--disabled" v-else>
            {{
              currentJunction.type === 'linear'
                ? `${$t('message.junction.pane.types.linear')}`
                : `${$t('message.junction.pane.types.chiseled')}`
            }}
          </div>
        </div>
        <div class="controls-footer__item" ref="count">
          <CadButton
            :is-disabled="cadIsDisabled"
            :tooltip="cadTooltip"
            :current-junction="junctionForSocket"
            ref="cadButton"
          ></CadButton>
          <div class="controls-footer__item-title">
            {{ $t(`message.junction.pane.${currentJunction.type === 'linear' ? 'length' : 'count' }`) }}
            <span class="require">*</span>
          </div>
          <tippy v-if="hasJunctionDrawing" to="junctionCountInput" :content="$i18n.t('message.cadTooltip.junctionCount')" arrow="true" maxWidth="316px" />
          <div class="controls-footer__item-input" name="junctionCountInput">
            <input
              type="number"
              class="input"
              min="1"
              max="1000"
              :class="{ error: isValidCount }"
              v-model.number="computedCount"
              step="1"
              :disabled="hasJunctionDrawing"
            />
            <span
              class="units"
              v-text="
                currentJunction.type === 'linear'
                  ? `${$t('message.units.mp')}`
                  : `${$t('message.units.pc')}`
              "
            ></span>
          </div>
        </div>
      </div>
    </div>
    <HeadPaneMenu placement="junction" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import HeadPaneMenu from '@/components/smart/HeadPaneMenu'
import GroupModal from '@/components/elements/Modals/Modal'
import JunctionsGroup from '@/components/smart/Junction/JunctionsGroup'
import { getItem } from '@/api'
import { isValidNumber } from '@/utils'
import CadButton from '../CadButton.vue'

export default {
  props: {
    junctionForSocket: Object
  },
  data: () => ({
    isModalOpen: false,
    isLoading: false
  }),
  components: {
    CadButton,
    GroupModal,
    JunctionsGroup,
    HeadPaneMenu
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_PARAM: 'UPDATE_JUNCTION_PARAM',
      UPDATE_GROUP_JUNCTION_LAYERS: 'UPDATE_GROUP_JUNCTION_LAYERS'
    }),
    updateJunctionCount(e) {
      if (!isNaN(e.target.valueAsNumber) && e.target.valueAsNumber > 0) {
        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'count',
          value:
            this.currentJunction.type === 'linear'
              ? e.target.valueAsNumber
              : Math.round(e.target.valueAsNumber)
        })
      }
    },
    updateJunctionType(e) {
      this.UPDATE_JUNCTION_PARAM({
        index: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        param: 'type',
        value: e.target.value
      })
      this.UPDATE_JUNCTION_PARAM({
        index: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        param: 'count',
        value: 0
      })
    },
    junctionItemName(itemId, groupId) {
      if (groupId) {
        const originalGroup = this.allJunctions.find(p => p.id === groupId)
        return originalGroup?.items.find(p => p.id === itemId).name
      } else {
        return this.currentJunction.variables
          ? Object.entries(this.currentJunction.variables).map(([key, value]) => `${key} = ${value}`).join(', ')
          : ''
      }
    },
    close() {
      this.isModalOpen = false
    },
    itemSelected(evt) {
      this.isModalOpen = false
      this.isLoading = true

      const id = evt.variables ? evt.groupId : evt.junctionId
      const type = evt.variables
        ? this.currentJunction.type
        : this.allJunctions
            ?.find(p => p.id === this.currentJunction.groupId)
            ?.items.find(p => p.id === id).type

      getItem(this.$i18n.locale, 'junction', id, evt.variables).then(response => {
        this.UPDATE_GROUP_JUNCTION_LAYERS({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          layers: response.data.layers
        })
        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'id',
          value: id
        })
        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'variables',
          value: evt.variables
        })
        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'type',
          value: type
        })
        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'count',
          value: 0
        })
        this.isLoading = false
        this.$root.onBodyScroll()
      })
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      allJunctions: state => state.junctions,
      responseJunctionLayers: state => state.junctionLayers
    }),
    cadTooltip() {
      if (this.sectorFromState.drawingId) {
        return this.currentJunction.type === 'linear'
          ? this.$i18n.t('message.cadTooltip.linearJunctions')
          : this.$i18n.t('message.cadTooltip.chiseledJunctions')
      }
      return this.$i18n.t('message.cadTooltip.drawingEmpty')
    },
    cadIsDisabled() {
      return (this.computedCount > 0 && !this.hasJunctionDrawing) || !this.sectorFromState.sectorImg
    },
    hasJunctionDrawing() {
      return this.sectorFromState.junctionsImg
        ? this.sectorFromState.junctionsImg.find((img) => img.junctionLayerId === this.currentJunctions[this.junctionIndex].junctionLayerId)
        : false
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    originalGroup() {
      return this.currentJunction.variables
        ? {
          ...this.allJunctions.find(p => p.id === this.currentJunction.id),
          variables: this.currentJunction.variables
        }
        : this.allJunctions.find(p => p.id === this.currentJunction.groupId)
    },
    isValidCount() {
      return !isValidNumber(this.currentJunction.count)
    },
    computedCount: {
      get() {
        return this.currentJunction.count
      },
      set(val) {
        const value = val === '' ? 0 : val

        if (isValidNumber(value, true)) {
          this.UPDATE_JUNCTION_PARAM({
            index: this.sectorIndex,
            junctionIndex: this.junctionIndex,
            param: 'count',
            value: this.currentJunction.type === 'linear' ? value : Math.round(value)
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.junction-info
  &__pane
    display: grid
    +media((grid-template-columns: (320: rem(16) 23fr rem(16), 650: 1fr 29fr 1fr 17fr, 960: 1fr 32fr 1fr 14fr)))
    &-controls
      grid-column-start: 2
    .type
      +media((grid-column: (320: '2 / 3', 650: '4 / 5', 960: '4 / 5')))
      @extend .head-controls__type
      &__text
        @extend .head-controls__type--text
      &__btn
        @extend .head-controls__type--btn
    .controls-header
      width: 100%
      padding: rem(15) 0
      box-shadow: inset 0 rem(-1) 0 #ECECEC
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      cursor: pointer
      &__content
        &-title
          font-style: normal
          font-weight: bold
          font-size: rem(14)
          line-height: 140%
          color: $black
          padding-bottom: rem(4)
        &-value
          @extend .fs-12
          color: $light-black
      .chevron
        height: 100%
        width: rem(40)
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        img
          transition: .5s
    .controls-footer
      display: grid
      +media((grid-template-columns: (320: 1fr, 960: 1fr 1fr)))
      padding: rem(12) 0
      +media((grid-column-start: (320: 2, 960: 1)))
      &__item
        display: flex
        flex-direction: row
        align-items: center
        +media((padding: (320: rem(4) 0, 960: 0)))
        +media((justify-content: (320: space-between, 960: flex-start)))
        &.v-error
          .controls-footer__item-input
            animation: blinking .5s linear infinite
        &-title
          @extend .fs-12
          color: $light-black
          padding-right: rem(12)
          display: inline-flex
          .require
            color: $red
        &-select
          width: rem(160)
          position: relative
          &:after
            content: ""
            position: absolute
            top: 50%
            transform: translateY(-50%)
            right: rem(15)
            height: rem(10)
            width: rem(10)
            background-image: url(~@/assets/img/select.svg)
            background-position: center
            background-repeat: no-repeat
            pointer-events: none
            background-size: contain
          &--disabled
            border-radius: rem(4)
            background: #ECECEC
            border-color: #ECECEC
            height: rem(40)
            @extend .fs-12
            font-weight: bold
            color: rgba(51, 51, 51, 0.3)
            display: flex
            flex-direction: row
            align-items: center
            padding-left: rem(14)
            &:after
              background-image: url(~@/assets/img/icons/select--disabled.svg)
          .select
            background: #FFFFFF
            border: rem(1) solid #D6D6D6
            width: 100%
            box-sizing: border-box
            border-radius: rem(4)
            height: rem(40)
            outline: none
            box-shadow: none
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: bold
            line-height: rem(15)
            color: $black
            font-size: rem(14)
            padding-left: rem(14)
            -webkit-appearance: none
            -moz-appearance: none
            cursor: pointer
        &-input
          @extend .input-with-units
          width: rem(100)
          .input
            width: rem(50)
            &.error
              color: $red
          .units
            font-weight: bold
            font-size: rem(12)
            line-height: 160%
            color: $dark-grey
      &__item:has(.cad__button-wrapper)
        .controls-footer__item-input
          +media((width: (320: rem(96), 960: rem(140))))
</style>
