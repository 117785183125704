<template>
  <section class="single-junction" v-if="allSystems.length && currentJunctions.length">
    <div class="container">
      <button class="link__back link__back--btn" @click="replaceCurrentJunction">
        <img src="@/assets/img/icons/breadcrumbs--back.svg" alt="<" class="icon" />
        {{ $t('message.stepButtons.replaceJunction') }}
      </button>
      <div class="junctions__list">
        <Junctions-nav :junctions="sectorFromState.junctions" :isShowDelete="true" />
      </div>
      <div class="title-with-btn">
        <div class="major-title single-system__page-title" v-if="!currentJunction.isCustom">
          {{ originalJunctionName }}
        </div>
        <Change-name :name="currentJunction.customJunctionName" :placement="'junction'" v-else />
        <delete-btn @btnClicked="deleteJunction" />
      </div>
      <SingleJunctionHeadPane ref="headPane" :junctionForSocket="currentJunctionForSocket" />
      <div class="content__panes-info">
        <img src="@/assets/img/icons/info.svg" alt="i" />
        <div class="text">
          {{ $t('message.junction.info') }}
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content__panes">
        <div class="content__panes-body">
          <component :is="currentJunction.junctionType"> </component>
        </div>
        <div class="content__panes-sidebar">
          <div class="items">
            <FileUploader v-if="isCustomJunction" />
            <JunctionImage :url="originalJunction.img_original" v-else />
            <SideBarControls
              :is-current-junction-count-valid="isCurrentJunctionCountValid"
              @showError="showError"
            />
          </div>
        </div>
      </div>
      <div class="step-buttons">
        <button @click="replaceCurrentJunction" class="btn btn-back">
          <img src="@/assets/img/icons/step--back.svg" alt="←" />
          <span class="btn__text"> {{ $t('message.stepButtons.replaceJunction') }} </span>
        </button>
        <button
          @click="goToResult"
          class="btn btn-next"
          :class="{ 'btn--disabled': !isAllJunctionsValid }"
        >
          <span class="btn__text">
            {{ $t('message.stepButtons.result') }}
          </span>
          <img src="@/assets/img/icons/step--next.svg" alt="→" />
        </button>
      </div>
    </div>
    <Fixed-trigger @fixedTriggerClicked="showSidebar" v-if="!isDesktop">
      <template>
        <img src="@/assets/img/icons/junction--sidebar.svg" alt="" />
      </template>
    </Fixed-trigger>
    <Modal :isModalOpen="isSidebarShow" :isShowClose="true" v-if="!isDesktop" @close="close">
      <template #body>
        <div class="sidebar--mobile">
          <FileUploader v-if="isCustomJunction" />
          <JunctionImage :url="originalJunction.img_original" v-else />
          <SideBarControls
            :is-current-junction-count-valid="isCurrentJunctionCountValid"
            @showError="showErrorFromModal"
          />
        </div>
      </template>
    </Modal>
    <leave-step-modal :is-modal-open="isLeaveStepModalOpen" @closeModal="closeLeaveStepModal" />
  </section>
</template>

<script>
import { isValidNumber, scrollAndHighLightErrorField } from '@/utils'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { isAllJunctionsValid } from '@/middlewares'
import DefaultJunction from '@/components/smart/Junction/SingleJunctionLayers'
import CustomJunction from '@/components/smart/Junction/SingleJunctionLayersCustom'
import FileUploader from '@/components/smart/uploader/FileUploader'
import SingleJunctionHeadPane from '@/components/smart/Junction/SingleJunctionHeadPane'
import SideBarControls from '@/components/smart/Junction/JunctionControlPanel'
import JunctionImage from '@/components/elements/Junction/JunctionImage'
import ChangeName from '@/components/smart/ChangeName'
import Modal from '@/components/elements/Modals/Modal'
import FixedTrigger from '@/components/elements/Dom/FixedTrigger'
import JunctionsNav from '@/components/elements/Junction/JunctionsNav'
import device from 'current-device'
import DeleteBtn from '@/components/elements/Junction/deleteBtn'
import LeaveStepModal from '@/components/elements/Modals/LeaveStepModal'
import { stepNumbers } from '@/utils/stepNumbers'
import { uuid } from 'vue-uuid'

export default {
  data: () => ({
    isEditable: false,
    isSidebarShow: false,
    isDesktop: '',
    isLeaveStepModalOpen: false
  }),
  components: {
    LeaveStepModal,
    DeleteBtn,
    FileUploader,
    SideBarControls,
    SingleJunctionHeadPane,
    JunctionImage,
    DefaultJunction,
    CustomJunction,
    ChangeName,
    Modal,
    FixedTrigger,
    JunctionsNav
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_PARAM: 'UPDATE_JUNCTION_PARAM'
    }),
    ...mapActions({
      deleteJunctionFromPage: 'deleteJunctionFromPage',
      updateStep: 'updateStep'
    }),
    showError() {
      const target = this.$refs.headPane.$refs.count
      scrollAndHighLightErrorField(target)
    },
    showErrorFromModal() {
      this.isSidebarShow = false
      setTimeout(() => {
        this.showError()
      }, 0)
    },
    closeLeaveStepModal() {
      this.isLeaveStepModalOpen = false
    },
    deleteJunction() {
      this.deleteJunctionFromPage({ sectorIndex: this.sectorIndex, junctionIndex: this.junctionIndex, lang: this.$i18n.locale })
    },
    focusInput() {
      this.isEditable = true
      this.$refs.title__input.focus()
    },
    updateCustomJunctionName(e) {
      this.UPDATE_JUNCTION_PARAM({
        index: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        param: 'customJunctionName',
        value: e.target.value
      })
    },
    goToResult() {
      if (this.isAllJunctionsValid) {
        this.$router.push(`/${this.$i18n.locale}/result`)
      } else if (!this.isCurrentJunctionCountValid) {
        this.showError()
      } else {
        this.isLeaveStepModalOpen = true
      }
    },
    showSidebar() {
      this.isSidebarShow = true
    },
    close() {
      this.isSidebarShow = false
    },
    replaceCurrentJunction() {
      this.$router.push({
        name: 'Junctions',
        params: {
          lang: `${this.$i18n.locale}`,
          id: `${this.sectorIndex}`,
          replacementJunction: this.currentJunction.uid,
          isReplace: true
        }
      })
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
    this.updateStep(stepNumbers.singleJunction)
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems',
      allJunctions: 'allPublishedJunctions'
    }),
    currentJunctionForSocket() {
      return {
        junctionIndex: this.junctionIndex,
        junctionImg: this.currentJunction?.isCustom ? this.currentJunction.drawingUrl : this.originalJunction?.img,
        junctionName: this.currentJunction?.isCustom ? this.currentJunction.customJunctionName : this.originalJunctionName,
        junctionType: this.currentJunction?.isCustom ? this.currentJunction.type : this.originalJunction?.type,
        junctionLayerId: this.currentJunction.junctionLayerId
          ? this.currentJunction.junctionLayerId
          : uuid.v4()
      }
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    isSingleJunction() {
      return !Object.prototype.hasOwnProperty.call(this.currentJunction, 'groupId')
    },
    originalJunction() {
      return this.isSingleJunction ? this.originalSingleJunction : this.originalGroupJunction
    },
    originalJunctionName() {
      return this.isSingleJunction
        ? this.originalSingleJunctionName
        : this.originalGroupJunctionName
    },
    originalSingleJunction() {
      return this.allJunctions.find(p => p.id === this.currentJunction.id)
    },
    originalSingleJunctionName() {
      const originalName = this.allJunctions.find(p => p.id === this.currentJunction.id)?.name

      return this.currentJunction.variables
        ? `${originalName} ${Object.entries(this.currentJunction.variables).map(([key, value]) => `${key} = ${value}`).join(', ')}`
        : originalName
    },
    originalGroupJunction() {
      const group = this.allJunctions.find(p => p.id === this.currentJunction.groupId).items
      return group.find(p => p.id === this.currentJunction.id)
    },
    originalGroupJunctionName() {
      const group = this.allJunctions.find(p => p.id === this.currentJunction.groupId)
      const groupJunction = group.items.find(j => j.id === this.currentJunction.id)
      return groupJunction.title
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isCustomJunction() {
      return this.currentJunction.isCustom
    },
    isAllJunctionsValid() {
      return isAllJunctionsValid(this.sectors)
    },
    isCurrentJunctionCountValid() {
      return isValidNumber(this.currentJunction.count)
    }
  }
}
</script>

<style lang="sass" scoped>
.single
  &-junction
    +media((z-index: (960: 1)))
    position: relative
    .title-with-btn
      @extend %title-with-btn
    .content
      &__panes
        display: grid
        grid-template-columns: 7fr 1fr 4fr
        +media((grid-template-columns: (320: 1fr, 960: 7fr 1fr 4fr)))
        &-body
          // overflow: hidden
        &-sidebar
          grid-column-start: 3
          +media((display: (320: none, 960: block)))
          .items
            // z-index: 1
            position: sticky
            top: rem(20)
.sidebar--mobile
  width: 92vw
  max-width: rem(320)
  padding-top: rem(40)
  margin: auto
  border-radius: rem(4)
  overflow-y: auto
  max-height: calc((var(--vh, 1vh)) * 0.98)
  box-sizing: border-box
.junctions__list
  padding: rem(32) 0 0
</style>
